.eb-job-stories {
  margin-bottom: 0;

  .list-entry {
    overflow: hidden;
  }

  .teaser-text-tile {
    position: relative;

    .body {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
    }

    .heading {
      position: relative;
      height: 100%;
      text-align: center;
      --my-headings-color: #{$eb-color-white};

      > a {
        height: 100%;
        padding: 4rem var(--my-default-margin);

        &:hover,
        &:focus {
          --my-font-weight-headings: 800;
        }
      }
    }
  }

  .text-overlay {
  }

  .link {
    display: none;
  }
}
