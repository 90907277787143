#joblist {
  html:not(.opencms-page-editor) & .joblist-debuginfo {
    display: none;
  }

  .joblist-filter {
    margin-bottom: 8rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 4rem;
    & > div {
      max-width: 100%;
    }
  }

  .joblist-filter-items {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    @include media-breakpoint-up(lg) {
      flex-wrap: nowrap;
    }
  }

  .joblist-filter-title {
  }

  .joblist-filter-multiselect {
    border: 2px solid var(--#{$prefix}btn-bg);
    option {
      padding: 0.5rem 1rem;
      padding-right: 3rem;
      &:checked {
        color: var(--#{$prefix}btn-fg);
        background-image: linear-gradient(
          0deg,
          var(--#{$prefix}btn-bg) 0%,
          var(--#{$prefix}btn-bg) 100%
        );
        background-color: var(--#{$prefix}btn-bg);
      }
    }
  }

  .joblist-filter-reset {
    cursor: pointer;
    display: block;
    margin-top: 1rem;
  }

  .joblist-item {
    margin-bottom: 4rem;
    border-bottom: 1px solid var(--#{$prefix}grey-lighter);
    padding-bottom: 4rem;

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    &:last-of-type {
      border-bottom: none;
      padding-bottom: 0;
    }

    &-details {
      display: flex;
      gap: 2rem;
      margin-top: 0.5rem;
    }
  }

  .joblist-item-category {
    color: var(--#{$prefix}link-color);
  }

  .joblist-item-title {
    display: block;
    color: var(--#{$prefix}main-theme);
    font-weight: var(--#{$prefix}font-weight-headings);
    font-family: var(--#{$prefix}font-family-headings);
    font-style: var(--#{$prefix}font-style-headings);
    font-size: 2.25rem;
  }

  .joblist-item-description {
    color: var(--#{$prefix}headings-color);
    font-family: var(--#{$prefix}font-family-headings);
    font-style: var(--#{$prefix}font-style-headings);
    font-size: 1.75rem;
  }

  .joblist-novalue  {
    margin-bottom: 2rem;
    padding: 2rem 0;
    font-size: 2rem;
    font-weight: bold;
    color: var(--#{$prefix}headings-color);
    text-align: center;
  }
}
