.type-media {
  --my-headings-color: #{$eb-color-bistum--dark};
  .intro-headline {
    text-align: center;
  }
  .sub-header {
    text-align: center;
    color: var(--my-body-color);
  }
}
