.eb-contact-list {
  .list-entries {
    @extend.row;
    display: flex;
    flex-wrap: wrap;
  }
  .list-entry {
    flex-basis: 100%;
    @include media-breakpoint-up(xl) {
      flex-basis: 50%;
    }
  }
}
