@use "sass:map";

@mixin background-gradient-fill($position, $colors) {
  &:nth-of-type(#{$position}) {
    @include interactive-gradient(0deg, $colors);
  }
}

.eb-job-tiles {
  .list-entry {
    $eb-job-tiles-colors: map.keys($eb-gradients);

    @for $i from 1 through 24 {
      $colorkey: (($i % 5) + 1);
      @include background-gradient-fill(
        $i,
        map.get($eb-gradients, nth($eb-job-tiles-colors, $colorkey))
      );
    }
  }

  .teaser-text-tile {
    display: flex;
    justify-content: space-between;

    &::after {
      display: none;
    }

    .heading {
      --my-headings-color: #{$eb-color-white};
      --my-font-weight-headings: 600;
      hyphens: manual;
      flex-grow: 1;

      > a {
        padding: 5rem var(--my-default-margin) 6rem;
        height: 100%;
      }

      .intro-headline {
        font-size: 1.8rem;
        text-align: center;
      }
    }

    .link {
      display: flex;
      .btn {
        --my-btn-bg: transparent;
        --my-btn-fg: #{$eb-color-white};
        --my-btn-hover-bg: var(--my-btn-bg);
        --my-btn-hover-fg: var(--my-btn-fg);
        width: 100%;
        padding: var(--my-default-margin) var(--my-default-margin) 4rem;
        font-weight: 400;
        margin: 0 auto;
        text-decoration: underline;
        font-size: 1.25rem;
      }
    }

    &:hover,
    &:focus {
      .heading {
        --my-font-weight-headings: 700;
      }
      .link {
        display: flex;
        .btn {
          font-weight: 600;
        }
      }
    }
  }
}
