.type-section.eb-header-text {
  display: flex;
  align-items: center;
  background-color: $eb-color-bistum--dark;

  &-gradient {
    @each $name, $value in $eb-gradients {
      &--#{$name} {
        @extend.eb-bg-color;
        @extend.eb-bg-gradient;
        @include gradient(180deg, #{$value});
      }
    }
  }

  .heading {
    --my-headings-color: #{$eb-color-white};
    font-weight: 800;
    text-transform: uppercase;
    margin: 12rem 3rem 3rem;

    @include media-breakpoint-up(sm) {
      margin: 7rem 12rem 3rem 3rem;
    }

    @include media-breakpoint-up(md) {
      max-width: 50%;
      margin: 12rem 10rem 5rem;
      .piece-heading {
        font-size: 4vw;
      }
    }

    @include media-breakpoint-up(lg) {
      max-width: 40%;
      margin: 14rem 10rem 7rem;
    }
  }
}
