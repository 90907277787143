.eb-jobalert {
  .bite-container {
    margin-bottom: var(--my-element-margin);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      @extend .h5;
      text-align: center;
    }

    .job-ads-button {
      @extend .btn;
      width: auto;
    }
  }
}

// BITE Job-Alert Popup
#bja-portals {
  --bja-colors-highlight: var(--my-main-theme);
  --bja-colors-foreground---checkbox--checked: var(--my-main-theme);

  font-size: var(--my-body-font-size);
  color: var(--my-body-color);
  font-family: var(--my-body-font-family);

  .bja-portal__backdrop {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .bja-portal__content {
    max-width: 110rem;
    margin: 10rem auto;
  }

  .bja-popover {
    padding: calc(var(--my-element-margin) * 2);
  }

  .bja-subscribe-container {
    font-family: var(--my-body-font-family);
    font-size: var(--my-body-font-size);
  }

  .bja-step-body {
    &__header {
      display: none;
    }

    &__headline {
      @extend .h2;
      @extend .eb-title;
    }

    &--subscribe__search-name {
      display: none;
    }

    &--subscribe-input__explanation {
      --my-body-font-size: 2.5rem;
      font-family: var(--my-body-font-family);
      font-size: var(--my-body-font-size);
      font-weight: var(--my-body-font-weight);
      line-height: var(--my-body-line-height);
      color: var(--my-body-color);
    }

    &--subscribe-input__explanation-required {
      --my-body-font-size: 1.5rem;
      font-size: var(--my-body-font-size);
    }
  }

  .bja-step-body__form {
    .bja-form-component-wrapper {
      margin: calc(var(--my-element-margin) * 2) 0;
      &__heading {
        @extend .h4;
        margin-bottom: calc(var(--my-element-margin) / 2);
      }
    }
    .bja-step-body__form__collapsible-section__heading {
      border-color: var(--my-btn-bg);
    }
    .bja-step-body__form__collapsible-section__heading__collapse-button__collapse-icon {
      width: auto;
    }

    .bja-input-component--personal-data {
      &__first-name {
      }
      &__middle-name {
      }
      &__last-name {
      }
    }
  }

  .bja-popover__actions {
    margin-top: var(--my-element-margin);

    .bja-button {
      @extend .btn;
      font-size: var(--my-body-font-size);
      min-width: 0;
      margin-right: var(--my-element-margin);

      &:last-of-type {
        --my-btn-bg: var(--my-disabled-color);
        --my-btn-hover-bg: var(--my-disabled-fg-color);
      }
    }
  }
}
.bja-ui-dropdown-popover {
  --bja-colors-background---drop-down__popover__option--selected: var(
    --my-main-theme
  );
}
