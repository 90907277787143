.area-header {
  position: absolute !important;
  z-index: 1000;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .head {
    background-color: transparent;
  }

  .header-group {
    width: 10%;
    min-width: 5.5rem;
    margin-right: var(--my-default-margin-small);

    @include media-breakpoint-up(md) {
      margin-right: var(--my-default-margin);
    }
  }
}

.h-group {
  position: relative;
  max-width: 150px;
}

.h-meta {
  position: absolute;
  right: 100%;
  top: 0;
  min-width: 80vw;
  padding-top: var(--my-default-margin);
  padding-right: calc(var(--my-default-margin) * 2);

  .header-links > ul > li > a {
    font-weight: 600;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}
