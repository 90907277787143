.accordion {
  .acco-header {
    --my-at-spacing-x: 2rem;
    --my-at-spacing-y: 1.5rem;
    --my-at-header-bg: var(--my-main-theme);
    --my-at-header-active-bg: #{$eb-color-bistum--dark};
    --my-at-header: #{$eb-color-white};
    // .acco-toggle:not(.collapsed)::after {
    //   color: $eb-color-orange;
    // }
  }

  .acco-body {
    --my-at-border: none;
    --my-at-content-padding-x: 2rem;
    --my-at-content-padding-y: 3rem;
    --my-acco-body-margin-top: -1rem;

    .type-section .body {
      width: initial;
      margin: initial;
      padding-top: initial;
    }
  }
}
