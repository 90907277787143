// montserrat-regular - latin
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(""),
    url($assetpath + "/fonts/montserrat-v25-latin-regular.woff2")
      format("woff2"),
    url($assetpath + "/fonts/montserrat-v25-latin-regular.woff") format("woff");
}

// montserrat-600 - latin
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local(""),
    url($assetpath + "/fonts/montserrat-v25-latin-600.woff2") format("woff2"),
    url($assetpath + "/fonts/montserrat-v25-latin-600.woff") format("woff");
}

// montserrat-regular - latin
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(""),
    url($assetpath + "/fonts/montserrat-v25-latin-regular.woff2")
      format("woff2"),
    url($assetpath + "/fonts/montserrat-v25-latin-regular.woff") format("woff");
}
/* montserrat-700 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: local(""),
    url($assetpath + "/fonts/montserrat-v25-latin-700.woff2") format("woff2"),
    url($assetpath + "/fonts/montserrat-v25-latin-700.woff") format("woff");
}

// montserrat-800 - latin
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local(""),
    url($assetpath + "/fonts/montserrat-v25-latin-800.woff2") format("woff2"),
    url($assetpath + "/fonts/montserrat-v25-latin-800.woff") format("woff");
}

// montserrat-italic - latin
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local(""),
    url($assetpath + "/fonts/montserrat-v25-latin-italic.woff2") format("woff2"),
    url($assetpath + "/fonts/montserrat-v25-latin-italic.woff") format("woff");
}
