.area-foot {
  margin-top: 6rem;

  .topfoot {
    --my-body-bg: #{$eb-color-white};
  }
  .subfoot {
    .container > * {
      @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      &::after {
        display: none;
      }
    }
  }

  .eb-links-social {
    @include media-breakpoint-down(md) {
      ul {
        padding-left: 0;
      }
    }

    font-size: 2.5rem;
    .facebook a:hover {
      color: #1877f2;
    }
    .instagram a:hover {
      color: #c13584;
    }
    .linkedin a:hover {
      color: #0077b5;
    }
  }
}
