.eb-job-benefits {
  .list-entries {
    display: flex;
    flex-wrap: wrap;
  }

  .list-entry {
    flex-basis: 100%;
    display: flex;

    @include media-breakpoint-up(sm) {
      flex-basis: 50%;
    }
    @include media-breakpoint-up(lg) {
      flex-basis: 33.333%;
      padding: 0px 2rem;
    }
    @include media-breakpoint-up(xl) {
      flex-basis: 25%;
    }

    .square-col {
      --my-square-text-overlay-padding: 0;
      padding-bottom: 0 !important;
      width: 100%;
    }

    .content {
      position: relative !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: auto;

      .effect-box {
        max-width: 18rem;
        width: 100%;

        .image-src-box {
          height: auto;
          padding-bottom: 0 !important;
        }

        img {
          object-fit: contain;
          position: relative;

          *[class*="eb-bg-gradient"] & {
            filter: brightness(255);
          }
        }
      }
    }

    .text-overlay {
      position: relative !important;
      background: transparent !important;
      text-align: center;

      .title,
      .preface {
        text-shadow: none;
        color: $eb-color-black;
        margin-bottom: 1.25rem;

        *[class*="eb-bg-gradient"] & {
          color: $eb-color-white;
        }
      }
      .preface {
        display: block;
        font-weight: normal;
        font-size: 1.5rem;
      }
    }
  }
}
