.eb-bodytext {
  @include media-breakpoint-up(xl) {
    .body,
    .heading {
      width: 75%;
      margin: 0 auto;
    }
  }

  .body {
    padding-top: 2rem;
  }

  .heading .piece-heading {
    font-size: $h2-font-size-mobile;
    @include media-breakpoint-up(lg) {
      font-size: $h2-font-size;
    }
  }

  .link {
    --my-text-to-link-margin: 4rem;
    display: flex;
    justify-content: center;
  }
}

.eb-title {
  --my-headings-color: #{$eb-color-bistum--dark};
  text-transform: uppercase;
  // --my-font-weight-headings: 700;
  .heading .piece-heading {
    font-size: $h1-font-size-mobile;
    @include media-breakpoint-up(lg) {
      font-size: $h1-font-size;
    }
  }
}
