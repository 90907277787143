.eb-header-image.type-slider {
  .slider-box {
    --my-slider-bg: transparent;
    --my-slider-fg: var(--my-headings-color);
  }

  .caption {
    margin: 0 auto;
    --my-default-margin-verysmall: 1rem;

    @include media-breakpoint-up(sm) {
      --my-default-margin-verysmall: 2rem;
    }
    @include media-breakpoint-up(md) {
      width: calc(100% - 10rem);
    }

    &.top {
      top: 10rem;
    }
    &.left {
      left: 10rem;
    }

    strong {
      text-shadow: none;
      font-weight: 800;
      text-transform: uppercase;
      font-size: $h1-font-size-mobile;

      @include media-breakpoint-up(md) {
        --my-slider-fg: #fff;
        max-width: 40%;
        font-size: 4vw;
      }
    }
  }
}
