// margin top
.u-mt-flush {
  margin-top: 0 !important;
}

.u-mt-md {
  margin-top: 4rem !important;
}

.u-mt-lg {
  margin-top: 10rem !important;
}

// margin bottom
.u-mb-flush {
  margin-bottom: 0 !important;
}

.u-mb-md {
  margin-bottom: 4rem !important;
}

.u-mb-lg {
  margin-bottom: 10rem !important;
}

// margin vertically
.u-my-flush {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.u-my-md {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.u-my-lg {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

// padding top
.u-pt-flush {
  padding-top: 0 !important;
}

.u-pt-md {
  padding-top: 4rem !important;
}

.u-pt-lg {
  padding-top: 10rem !important;
}

// padding bottom
.u-pb-flush {
  padding-bottom: 0 !important;
}

.u-pb-md {
  padding-bottom: 4rem !important;
}

.u-pb-lg {
  padding-bottom: 10rem !important;
}

// padding vertically
.u-py-flush {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.u-py-md {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.u-py-lg {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

// max-width
.u-measure-medium {
  max-width: 80ch;
  margin: 0 auto;
}

// display none
body:not(.oc-edit-buttons-visible) {
  .u-hidden {
    display: none;
  }
}
