@mixin gradient($direction: 0deg, $colors) {
  background: linear-gradient($direction, $colors);
}

@mixin interactive-gradient($direction: 0deg, $colors) {
  background: linear-gradient($direction, $colors);
  &:hover,
  &:focus {
    background: linear-gradient($direction + 180, $colors);
  }
}
