.area-content {
  padding: 4rem 0;

  &:first-of-type {
    padding-top: 0;
  }

  &--flush-top {
    padding-top: 0;
  }
  &--flush-bottom {
    padding-bottom: 0;
  }
}

.eb-row-bg {
  margin: 4rem 0;

  &--grey {
    background-color: $eb-color-grey;
  }
}

.eb-bg-color {
  background-color: var(--my-body-color);
  &.colored-row.bg-theme {
    --my-body-color: #{$eb-color-white};
    --my-headings-color: var(--my-body-color);
    --my-link-color: #fff;
    --my-link-hover-color: var(--#{$prefix}grey);
  }

  .eb-title {
    --my-headings-color: #{$eb-color-white};
  }

  @each $name, $value in $eb-colors {
    &--#{$name} {
      @extend.eb-bg-color;
      --my-body-color: #{$value};
    }
  }
}

.eb-bg-gradient {
  --my-body-color: #{$eb-color-white};
  --my-headings-color: var(--my-body-color);
  .eb-title {
    --my-headings-color: var(--my-body-color);
  }
  .body {
    color: var(--my-body-color);
  }
  .pagination {
    --my-pag-bg: transparent;
    --my-pag-hover-bg-color: var(--my-link-hover-color);
    a:not([href]):not([class]) {
      cursor: pointer;
    }
  }

  @each $name, $value in $eb-gradients {
    &--#{$name} {
      @extend.eb-bg-color;
      @extend.eb-bg-gradient;
      @include gradient(180deg, #{$value});
    }
  }
}
