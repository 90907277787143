#nav-toggle-group {
  margin-top: var(--my-default-margin);
  margin-left: var(--my-default-margin);
}

.nav-main-container {
  left: 0;
  transform: translateX(-100%);
  transition: transform 1s;
  background-color: $eb-color-bistum--dark;

  .active-nav & {
    opacity: 1;
    transform: none;
  }
}

.nav-main-items {
  li > a {
    &:hover {
      background-color: $eb-color-bistum;
    }
  }
}

.nav-main-mobile-logo {
  display: none;
}

@include media-breakpoint-down(md) {
  .header-links {
    display: none;
  }
}
